import { createContext, useState, useEffect } from 'react';

const QuestionAnswerContext = createContext();

const QuestionAnswerContextProvider = ({ children }) => {
    const [matrixData, setMatrixData] = useState([]);
    const [priceFormula, setPriceFormula] = useState([]);

    useEffect(() => {
        const getMatrixData = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_SERVER_URL + 'Product/GetMatrixData'
                );
                const data = await response.json();
                setMatrixData(data);
            } catch (error) {
                console.log('error fetching matrix data', error);
            }
        };
        getMatrixData();
    }, []);

    useEffect(() => {
        const getPriceFormula = async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_SERVER_URL + 'Product/GetPriceFormula'
                );
                const data = await response.json();
                setPriceFormula(data);
            } catch (error) {
                console.log('error fetching price formula', error);
            }
        };
        getPriceFormula();
    }, []);

    return (
        <QuestionAnswerContext.Provider
            value={{
                matrixData,
                priceFormula,
            }}
        >
            {/* render children only when all required data is available */}
            {matrixData.length > 0 && priceFormula.length > 0 && children}
        </QuestionAnswerContext.Provider>
    );
};

export { QuestionAnswerContext, QuestionAnswerContextProvider };
