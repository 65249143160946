import React, {
    useState,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useCallback,
} from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
    Card,
    CardBody,
    FormGroup,
    Row,
    Col,
    Button,
    Label,
    Input
} from 'reactstrap';
import CreatableSelect from 'react-select/creatable';
import Spinner from '../../common/Spinner';
import { QuestionAnswerContext } from './QuestionAnswerContext';

import CategoriesDropdown from './CategoriesDropdown';
import ProductsDropdown from './ProductsDropdown';
import Question from './Question';
import AddtoCartButton from './AddToCartButton';
import EvaluateRule from './EvaluateRule';
import { calculatePrice } from './PriceCalculator';
import TitleHeader from '../../common/TitleHeader';
import { isEmpty } from '../../lib/utils';


const PriceElement = ({price, show, currency}) => {
    
    if(show)
    {
        
        if(price === "TBD")
            return (<span>TBD</span>);
        else{
            return (<span>{ `$${parseFloat(price).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            ${currency}`}</span>);
          }
    }
    return (<></>);
}

const QuestionsContainer = ({ queryParams, categories }) => {
    const NEW_PROJECT_ID = 0;
    // api url
    const api_url = process.env.REACT_APP_SERVER_URL;
     // store page loading state
    const [isLoading, setIsLoading] = useState(false);
    const [isGuest, setIsGuest] = useState(false);
    // store customer projects
    const [projects, setProjects] = useState([]);
    // store selected category
    const [selectedCategory, setSelectedCategory] = useState(undefined);
    // store project, title, quantity etc.
    const [title, setTitle] = useState('');
    const [selectedProjectOption, setSelectedProjectOption] = useState();
    const [notes, setNotes] = useState('');
    const [qty, setQty] = useState(1);
    const [isPrivate, setIsPrivate] = useState(false);
    // store selected productsetMyProductPrice
    const [selectedProduct, setSelectedProduct] = useState(undefined);
    // store questions for selected product
    const [questions, setQuestions] = useState([]);
    // store answer given by user for each question
    const [answers, setAnswers] = useState();
    // store the flag to determine if user has given a custom answer to any of the questions
    const [customAnswerGiven, setCustomAnswerGiven] = useState(false);
    //store calculated price
    const [showMyProductPrice, setShowMyProductPrice] = useState(false);
    const [myProductPrice, setMyProductPrice] = useState();
    const [configuredPrice, setConfiguredPrice] = useState();
    const [configuredNetPrice , setConfiguredNetPrice] = useState();
    const [showMSRPPrice, setShowMSRPPrice] = useState(true);
    const [msrpPrice, setMSRPPrice] = useState();
    const [showWholeSalePrice, setShowWholeSalePrice] = useState(true);
    const [wholesalePrice, setWholesalePrice] = useState();
    // store the rules evaluation results based on the answer given
    const [ruleEvaluations, setRuleEvaluations] = useState({});
    const [readOnly, setReadOnly] = useState(false);

    const { matrixData, priceFormula } = useContext(QuestionAnswerContext);

    // ref variables
    // store whether initial state is loaded or not
    const isInitialStateLoaded = useRef(false);
    // store config header
    const configHeader = useRef({
        customerNo: queryParams.customerNo,
        email: queryParams.email,
        configId: queryParams.configID ? queryParams.configID : 0,
        currency: queryParams.currency,
        wareohuse: queryParams.warehouse
    });

    // check if the question is mandatory or not
    const isQuestionRequired = (questionObj) => {
         // if rule present, give preference to rule. otherwise check isRequired on question
        const { ruleName } = questionObj.question;
        if (!isEmpty(ruleName) && Object.hasOwn(ruleEvaluations, ruleName) && ruleEvaluations[ruleName])
            return questionObj.rules.some((rule) => rule.isRequired);
        else 
           return questionObj.question.isRequired;
         
    };

    // save product configuration
    const handleSaveButtonClick = async (e) => {
        e.preventDefault();
        // mandatory fields validation
        if (!selectedProjectOption) {
            toast.error('Please select Project');
            return false;
        }
        if (isEmpty(title)) {
            toast.error('Please enter Title');
            return false;
        }
        // mandatory questions validation
        for (const questionObj of questions) {
     
            const required = isQuestionRequired(questionObj);
 
            if (required && !answers[questionObj.question.variable]) {
                toast.error('Please answer the questions marked with *');
                return false;
            }
        }

        try {
            setIsLoading(true);
            let { value: projectId } = selectedProjectOption;
          
            // check if new project is to be cretated
            if (projectId === NEW_PROJECT_ID) {
                
                const newProject = await createProject(
                    selectedProjectOption.label
                );
                if (newProject == null) {
                    throw new Error('Error creating new project');
                }
                setProjects((prev) => [...prev, newProject]);
                setSelectedProjectOption({
                    label: newProject.projectName,
                    value: newProject.id,
                });
                projectId = newProject.id;
            }

            // save product configuration
            const newSessionDetails = [];
            for (const [condQuestion, condAnswer] of Object.entries(answers)) {
                newSessionDetails.push({
                    CondQuestion: condQuestion,
                    CondAnswer: condAnswer,
                });
            }
            let payload = {
                CustomerNo: configHeader.current.customerNo,
                Price: customAnswerGiven ? 'TBD' : configuredNetPrice.toString(),
                idProject: projectId,
                CreatedBy : configHeader.current.email,
                Title: title,
                Answers: newSessionDetails,
                Qty: qty,
                Notes: notes,
                IsPrivate: isPrivate
            };
            
            // if new config or clone, save new, else edit existing config
            let url = `${api_url}Product/SessionHeader`;
            let api_method = axios.post;
            if (configHeader.current.configId !== 0) {
                url = `${api_url}Product/UpdateProductConfiguration/${configHeader.current.configId}`;
                api_method = axios.put;
            }

            const apiResponse = await api_method(url, payload, {
                headers: { 'Content-Type': 'application/json' },
            });
            configHeader.current.configId = apiResponse.data.configID;
            

            // commented below code as we are getting error in optiConfig
           /*         var optiConfig =  newSessionDetails ? 
                    Object.fromEntries(newSessionDetails.filter(sd =>  sd.CondQuestion !== "IV_CUST_CURRNCY")
                    .map(({CondQuestion,  CondAnswer
                      }) => [CondQuestion, CondAnswer]))
                    : null;
            var productID = optiConfig.PRODUCT_ID;
            delete optiConfig["PRODUCT_ID"];
                    */

            /*------Add to cart-----*/
            if(e.target.id === "addToCart") {
                url = `${api_url}Product/GetProductConfigurationById/${configHeader.current.configId}/Clean`;
                api_method = axios.get;
    
                const apiResponse2 = await api_method(url, payload, {
                    headers: { 'Content-Type': 'application/json' },
                });
                var optiConfig = apiResponse2.data.sessionConfigurationDetails.filter(sd =>   sd.configDetailVariable !== "IV_CUST_CURRNCY" && sd.configDetailVariable !== "BUSINESS_UNIT_IN" && sd.configDetailVariable !== "PRODUCT_ID")
                                                                    .map(s => [s.configDetailVariable, s.answerText]);
                console.log(optiConfig);      
                
                 

/*
                var optiConfig =  newSessionDetails ? 
                        newSessionDetails.filter(sd =>  sd.CondQuestion !== "IV_CUST_CURRNCY" && sd.CondQuestion !== "PRODUCT_ID")
                        .map(({CondQuestion,  CondAnswer
                        }) => [CondQuestion, CondAnswer])
                        : null;
                        console.log("optiConfig", optiConfig);
    */
                if(configuredPrice === "TBD")
                {
                    url = `${api_url}Product/SendforReview/${configHeader.current.configId}`;
                    api_method = axios.put;
                    const apiResponse3 = await api_method(url, payload, {
                        headers: { 'Content-Type': 'application/json' },
                    });
                    
                }
                else if(queryParams.canAddToCart && optiConfig)
                {
                    //send message to parent window
                    var postMessage =  {
                        "Status": "Complete",
                        "ConfigurationId":   configHeader.current.configId,
                        "ConfigurationOptions": optiConfig,
                        "Product_Id": newSessionDetails.filter(sd => sd.CondQuestion === "PRODUCT_ID")[0].CondAnswer ,//productID,
                        "Quantity":qty 
                    };
                    console.log("postMessage", postMessage);
                    window.parent.postMessage(postMessage, "*");
                }
            }

       
            

            toast.success('Configuration saved successfully.');
            return true;
        } catch (error) {
            console.log('error saving the product configuration', error);
            toast.error('Error saving the product configuration.');
        } finally {
            setIsLoading(false);
        }
    };

    // use memo for finding out the matrix variables on which the price calc is dependent.
    // This is cached function. Runs only hen Matrix data changes (if at all).
    const priceMatrixDependencies = useMemo(
        () => getPriceMatrixDependencies(matrixData),
        [matrixData]
    );

    function getPriceMatrixDependencies(matrixData) {
        let priceDependencyItems = new Set();
        for (let item of matrixData) {
            const { variable1, variable2, variable3, variable4, variable5 } =
                item.matrix;
            if (!isEmpty(variable1)) priceDependencyItems.add(variable1);
            if (!isEmpty(variable2)) priceDependencyItems.add(variable2);
            if (!isEmpty(variable3)) priceDependencyItems.add(variable3);
            if (!isEmpty(variable4)) priceDependencyItems.add(variable4);
            if (!isEmpty(variable5)) priceDependencyItems.add(variable5);
        }
        return priceDependencyItems;
    }

    // handle category change
    const handleCategoryChange = (category) => {
        setSelectedCategory(category);
        //reset selected product, answers, quantity etc.
        setSelectedProduct(undefined);
        setAnswers({});
        setQty(1);
    };

    // handle product change
    const handleProductChange = useCallback(
        async (product) => {
            setSelectedProduct(product);
            setCustomAnswerGiven(false);

            if (product === undefined) return;

            setIsLoading(true);
            // set quantity and initial price
            setQty(1);
   

            checkPricingPermissions(queryParams.pricing, product, 0);
            

            // set productID and currency
             
            let defaultAnswers = {
                PRODUCT_ID: product.productId,
                IV_CUST_CURRNCY: configHeader.current.currency,
                BUSINESS_UNIT_IN: configHeader.current.warehouse
            };

            // fetch questions for the product
            const newQuestions = await getQuestions(product.configModel, product.productId);
            setQuestions(newQuestions);
            // evaluate the rules used in the questions and options.
            // also set default answers based on the default options
            const dependentRules = getRulesForQuestions(newQuestions);
            for (let questionObj of newQuestions) {
                
                const defaultOption = questionObj.options.find(
                    (option) => option.isDefault
                );
                if (defaultOption) {
                    defaultAnswers[questionObj.question.variable] =
                        defaultOption.optionValue;
                }
            }
            setAnswers(defaultAnswers);
 
            const rulesEvalResult = evaluateRules(
                dependentRules,
                defaultAnswers,
                matrixData
            );
            setRuleEvaluations(rulesEvalResult);
            setIsLoading(false);
        },
        [matrixData,queryParams.pricing]
    );

    // handle answer selection
    const handleAnswer = (questionVariable, choiceValue) => {
        const newAnswers = {
            ...answers,
            [questionVariable]: choiceValue,
        };
        let customAnswerFlag = false;
        let triggerPriceCalc = false; // flag to force price calculation
        if (priceMatrixDependencies.has(questionVariable))
            triggerPriceCalc = true;

        // evaluate rules dependent upon answered question
        let dependentRules = {};
        for (let questionObj of questions) {
            if (
                questionObj.rules.some(
                    (rule) => rule.condVariable === questionVariable
                )
            ) {
                dependentRules[questionObj.question.ruleName] =
                    questionObj.rules;
            }
            for (let option of questionObj.options) {
                if (
                    option.rules.some(
                        (rule) => rule.condVariable === questionVariable
                    )
                ) {
                    dependentRules[option.ruleName] = option.rules;
                }
            }
        }
        
        const newRulesEvaluations = evaluateRules(
            dependentRules,
            newAnswers,
            matrixData
        );
        const rulesEvalResult = { ...ruleEvaluations, ...newRulesEvaluations };
        setRuleEvaluations(rulesEvalResult);

        // if a question would become invisible by virtue of any rule evaluating to false
        // because of the new answer given, remove the answer corresponding to that question
        // from answers object.
        //example: User selects 'Marine ready', then selects 'Marine Gland' in the dependent
        // question, then again deselects 'Marine Ready' in the previous question. In that
        // case the 'Marine Gland' answer should be removed from the answers object
        for (let questionObj of questions) {
            const { variable, ruleName } = questionObj.question;
            if (!isEmpty(ruleName) && rulesEvalResult[ruleName] === false) {
                if (!isEmpty(newAnswers[variable])) {
                    delete newAnswers[variable];
                    // check if price calc needs to be forced
                    if (priceMatrixDependencies.has(variable))
                        triggerPriceCalc = true;
                }
            }

            // get the options that would become visible as a result of the new answer.
            // if the visible options do not contain the previously given answer, delete
            // the answer
            // Example: user selected 'Standard' for the 'Standard or Marine' question.
            // Then selected 'Light titanium' as color option, which is only available for
            // standard products. Then user selected 'Marine Ready' again in previous question.
            // In that case, the 'Light titanium' color option that was selected earlier,
            // is invalid and should be removed from answers
            if (
                !isEmpty(newAnswers[variable]) && // answer present
                questionObj.options.length > 0 // options present (i.e. not a textbox question)
            ) {
                const visibleOptions = questionObj.options.filter(
                    (option) =>
                        isEmpty(option.ruleName) || // no rule present
                        (!isEmpty(option.ruleName) && // rule evaluates to true
                            rulesEvalResult[option.ruleName] === true)
                );
                // if the answer is not present in visible options,
                // set default option (if available), else delete the answer
                const answerPresentInVisibleOptions = visibleOptions.some(
                    (option) => option.optionValue === newAnswers[variable]
                );
                if (!answerPresentInVisibleOptions) {
                    const defaultOption = visibleOptions.find(
                        (option) => option.isDefault
                    );
                    if (defaultOption) {
                        newAnswers[variable] = defaultOption.optionValue;
                    } else {
                        delete newAnswers[variable];
                    }
                    // check if price calc needs to be forced
                    if (priceMatrixDependencies.has(variable))
                        triggerPriceCalc = true;
                }

                // check if custom answer given
                
                if (isCustomAnswer(newAnswers[variable], visibleOptions))
                    customAnswerFlag = true;
            }
        }

 
        setCustomAnswerGiven(customAnswerFlag);

        setAnswers(newAnswers);

        // calculate price if the answered question affects price calculation
        
        if (triggerPriceCalc) {
            const price = calculatePrice(
                priceFormula,
                matrixData,
                newAnswers,
                selectedProduct,
                queryParams.pricing
            );
            if(!customAnswerFlag){
                setConfiguredPrice(price);
                setConfiguredNetPrice(price * qty);
            } 
            else{
                setConfiguredPrice("TBD");
                setConfiguredNetPrice("TBD");
            }
            
        }
    };

    // handle quantity change
    const handleQtyChange = (event) => {
        let newQty = event.target.value;
        
        if (!isNaN(newQty) && newQty > 0) {
            
            setQty(newQty);
            if (!customAnswerGiven && !isNaN(configuredPrice)) {
                //const singleItemPrice = configuredPrice / newQty;
                //setConfiguredPrice(singleItemPrice);
                setConfiguredNetPrice(newQty * configuredPrice);
            }
        }
    };

    // handle project change
    const handleProjectChange = (newValue, action) => {
        if (action?.action === 'create-option') {
            setSelectedProjectOption({
                label: newValue.label,
                value: NEW_PROJECT_ID, // cue for creating new project
            });
        } else {
            setSelectedProjectOption(newValue);
        }
    };

    // function to fetch questions for given config model
    const getQuestions = async (configModel, productId) => {
         
        try {
            const apiResponse = await fetch(
                process.env.REACT_APP_SERVER_URL +
                    `Product/QuestionsByProduct/${configModel}/${productId}`
            );
            const data = await apiResponse.json();
            return data;
        } catch (error) {
            toast.error('Error getting questions list.');
        } finally {
            setIsLoading(false);
        }
    };

    // function to create new project
    const createProject = async (projectName) => {
        if(!isGuest)
        {
            try {
            
                const payload = {
                    projectName: projectName,
                    customerNumber: configHeader.current.customerNo,
                    createdBy: configHeader.current.email,
                    status: 'New',
                };
                const apiResponse = await axios.post(
                    `${api_url}Product/SaveProject`,
                    payload,
                    {
                        headers: { 'Content-Type': 'application/json' },
                    }
                );
                const newProject = apiResponse.data;
                return newProject;
            } catch (error) {
                console.log(`Could not create project ${projectName}`);
                toast.error(`Could not create project ${projectName}`);
                return null;
            }
        }
        return null;
        
    };

    const searchParam = useLocation().search;
    const encryptedCustNo = encodeURIComponent(
        new URLSearchParams(searchParam).get('c')
    );
    const encryptedWarehouse = encodeURIComponent(
        new URLSearchParams(searchParam).get('wh')
    );

    // load initial state
    useEffect(() => {
        const { productID, configID, cloneID, pricing, isG } = queryParams;
        setIsGuest(isG);
        const populateState = async () => {
            
            if (isInitialStateLoaded.current === true) return;
            isInitialStateLoaded.current = true;
            setIsLoading(true);
            
            // fetch customer projects
            const resp = await axios.get(
                `${api_url}Product/GetProjectsByCustomer/${encryptedCustNo}`
            );
            const customerProjects = resp.data;
            setProjects(customerProjects);
        
                if (!isEmpty(configID) || !isEmpty(cloneID)) {
                    // fetch the given product configuration
                    const configResponse = await axios.get(
                        `${api_url}Product/GetProductConfigurationById?cId=${configID ?? cloneID }&pr=${pricing}`
                    );


                    const productConfig = configResponse.data;
                    // set selected product and category
                    let { product, sessionConfigurationDetails } = productConfig;
                    
                    const productResponse = await axios.get(
                        `${api_url}Product/GetProductById?c=${encryptedCustNo}&id=${product.productId}&wh=${encryptedWarehouse}`
                    );
                    
                    product = productResponse.data;
                    if (!product) {
                        toast.error('Invalid product configuration');
                        setIsLoading(false);
                        return;
                    }
                    
                    setSelectedProduct(product);
                    //await handleProductChange(product);
                    //setMSRPPrice(product.msrp);
                    //setWholesalePrice(product.wholeSalePrice);
                    const productCategory = categories.find(
                        (x) => x.categoryId === product.categoryId
                    );
                    if (!productCategory) {
                        toast.error(
                            'Category of selected product not found or is inactive'
                        );
                        setIsLoading(false);
                        return;
                    }
                    setSelectedCategory(productCategory);

                    
                    

                    const selectedProject = customerProjects.find(
                        (p) => p.id === productConfig.idProject
                    );
                    
                    if (
                        !selectedProject ||
                        selectedProject.customerNumber !==
                            configHeader.current.customerNo
                    ) {
                        toast.error('Customer project not found');
                        setIsLoading(false);
                        return;
                    }
                    setSelectedProjectOption({
                        label: selectedProject.projectName,
                        value: selectedProject.id,
                    });
                    configHeader.current.currency =
                        sessionConfigurationDetails.find(
                            (x) => x.configDetailVariable === 'IV_CUST_CURRNCY'
                        ).answerText;
                    // set answers
                    let newAnswers = {};
                    
                    for (const item of sessionConfigurationDetails) {
                        const { configDetailVariable, answerText } = item;
                        newAnswers[configDetailVariable] = answerText;
                    }
                    setAnswers(newAnswers);
                    // set product price, title, project name etc.
                    setIsPrivate(productConfig.isPrivate);
                    setTitle(productConfig.title);
                    setNotes(productConfig.notes);

                    setQty(productConfig.qty);
                    // if price is 'TBD' ie. a custom answer is given, set the state value
                    if (
                        productConfig.price === 'TBD' ||
                        isNaN(productConfig.price) ||
                        productConfig.price <= 0
                    ) {
                        setCustomAnswerGiven(true);
                    }
              
                    checkPricingPermissions(pricing,product, productConfig.price);
                    // set questions
                    const newQuestions = await getQuestions(product.configModel, product.productId);
                    setQuestions(newQuestions);
              
                    // evaluate rules and set state afresh
                    const dependentRules = getRulesForQuestions(newQuestions);
               
                    const rulesEvalResult = evaluateRules(
                        dependentRules,
                        newAnswers,
                        matrixData
                    );
                  
                    setRuleEvaluations(rulesEvalResult);
                }
            
             if (!isEmpty(productID)) {
                // set selected product and category
                const productResponse = await axios.get(
                    `${api_url}Product/GetProductById?c=${encryptedCustNo}&id=${productID}&wh=${encryptedWarehouse}`
                );
 
                const product = productResponse.data;
                if (!product) {
                    toast.error('Invalid product sku');
                    setIsLoading(false);
                    return;
                }
                else{
                    setReadOnly(true);
                }
                setSelectedProduct(product);

                const productCategory = categories.find(
                    (x) => x.categoryId === product.categoryId
                );
                if (!productCategory) {
                    toast.error(
                        'Category of selected product not found or is inactive'
                    );
                    setIsLoading(false);
                    return;
                }
                setSelectedCategory(productCategory);

                await handleProductChange(product);
          
                checkPricingPermissions(pricing, product, 0);
            }
           
            //incoming config ID

            setIsLoading(false);
        };

        populateState();
    }, [
        api_url,
        queryParams,
        encryptedCustNo,
        categories,
        matrixData,
        handleProductChange,
    ]);

    function checkPricingPermissions(pricing, product, configPrice)
    {
        let tempConfigPrice = configPrice;

        if(product){
            setMSRPPrice(product.msrp);
            setWholesalePrice(product.wholeSalePrice);
            setMyProductPrice(product.basePrice);
    
            if(pricing.length > 0)
            {
                var pf = pricing.split(",");
    
                if(pf[2] === "1")
                {
                    setShowMyProductPrice(true);
                    tempConfigPrice = configPrice === "TBD" ? "TBD" : product.basePrice;
                  
                }
    
                if(pf[1] === "1")
                {
                    setShowWholeSalePrice(true);
                    if(pf[2] === "0")
                        tempConfigPrice = configPrice === "TBD" ? "TBD" : product.wholeSalePrice; 
                }
                else{
                    setShowWholeSalePrice(false);
                }
    
                if(pf[0] === "1")  //Show MSRP
                {
                    setShowMSRPPrice(true);
                    if(pf[2] === "0" && pf[1] === "0")
                    tempConfigPrice = configPrice === "TBD" ? "TBD" : product.msrp; 
                }
                else{
                    setShowMSRPPrice(false);
                }
                 
                
            }
    
            else{
                setMSRPPrice(true);
                setWholesalePrice(true);
                setShowMyProductPrice(true);
            }
        }

        if(configPrice !== "TBD" && configPrice !== 0)
        {
            setConfiguredPrice(configPrice);
            setConfiguredNetPrice(qty * configPrice);
        }
 
        else{
            if(tempConfigPrice !== "TBD")
            {
                setConfiguredPrice(tempConfigPrice);
                setConfiguredNetPrice(qty * tempConfigPrice);
            }
            
            else{
                setConfiguredPrice("TBD");
                setConfiguredNetPrice("TBD");
            }
        }
       
    }



    return (
        <div>
            <Card>
                
                    <TitleHeader show={false}></TitleHeader>
          
                <CardBody
                    style={{ minHeight: '85vh', backgroundColor: '#e9ecee' }}
                >
                    {isLoading && <Spinner />}
                    <Row>
                        <Col>
                            <Card className='innerForm'>
                                <CardBody
                                    style={{
                                        backgroundColor: '#fffff',
                                        boxShadow:
                                            'rgba(0, 0, 0, 0.1) 0px 12px 24px 0px ',
                                    }}
                                >
                                    <Row>
                                        <Col md='9'>
                                            <h3 className='qContainerTitle'>Configure Product</h3>
                                        </Col>
                                        <Col md='3'>
                                            <FormGroup check>
                                                <Input
                                                    id="chkPrivate"
                                                    type="checkbox"
                                                    onChange={ ()=>{
                                                        setIsPrivate(!isPrivate);
                                                    }}
                                                    />
                                                    {' '}
                                                    <Label check>
                                                    Make Private Only
                                                    </Label>
                                                </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        {/* project */}
                                        <Col md='6'>
                                            <FormGroup>
                                                <Label> 
                                                    <span className='requiredQ'>
                                                        Project
                                                    </span>
                                                </Label>
                                                <CreatableSelect
                                                    isClearable
                                                    isDisabled={isLoading }
                                                    onChange={
                                                        handleProjectChange
                                                    }
                                                    options={projects.map(
                                                        (p) => ({
                                                            label: p.projectName,
                                                            value: p.id,
                                                        })
                                                    )}
                                                    value={
                                                        selectedProjectOption
                                                    }
                                                    placeholder='Select or Enter Project'
                                                    styles={
                                                        projectDropdownStyles
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                        {/* title */}
                                        <Col md='6'>
                                            <FormGroup>
                                                <Label>
                                                    <span className='requiredQ'>
                                                        Project Zone/Location
                                                    </span>
                                                </Label>
                                                <input
                                                    type='text'
                                                    name='title'
                                                    value={title}
                                                    className='form-control'
                                                    placeholder='Enter title'
                                                    onChange={(event) =>
                                                        setTitle(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        {/* categories */}
                                        <Col md='6'>
                                            <FormGroup>
                                                <CategoriesDropdown
                                                    onSelect={
                                                        handleCategoryChange
                                                    }
                                                    categories={categories}
                                                    selectedCategory={
                                                        selectedCategory
                                                    }
                                                    isReadOnly={readOnly}
                                                />
                                            </FormGroup>
                                        </Col>
                                        {/* products */}
                                        {selectedCategory && (
                                            <Col md='6'>
                                                <FormGroup>
                                                    <ProductsDropdown
                                                        category={
                                                            selectedCategory
                                                        }
                                                        onSelect={
                                                            handleProductChange
                                                        }
                                                        selectedProduct={
                                                            selectedProduct
                                                        }
                                                        customerNumber={encryptedCustNo}
                                                        isReadOnly={readOnly}
                                                        warehouse={encryptedWarehouse}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        )}
                                    </Row>
                                    <Row>
                                        {/* questions */}
                                        <Col md='6'>
                                            {selectedProduct && (
                                                <>
                                                    <small
                                                        style={{ color: 'red' }}
                                                    >
                                                        * denotes a required
                                                        question
                                                    </small>
                                                    
                                                    {questions.map(
                                                        (questionObj) => (
                                                            <Question
                                                                key={`${selectedProduct.productId}_${questionObj.question.variable}`}
                                                                product={
                                                                    selectedProduct
                                                                }
                                                                questionObj={
                                                                    questionObj
                                                                }
                                                                selectedAnswer={
                                                                    answers[
                                                                        questionObj
                                                                            .question
                                                                            .variable
                                                                    ]
                                                                }
                                                                isRequired={isQuestionRequired(
                                                                    questionObj
                                                                )}
                                                                ruleEvaluations={
                                                                    ruleEvaluations
                                                                }
                                                                onAnswerGiven={
                                                                    handleAnswer
                                                                }
                                                            />
                                                        )
                                                    )}
                                                </>
                                            )}
                                            {/* notes */}
                                            {selectedProduct && (
                                                <Row
                                                    style={{
                                                        marginTop: '20px',
                                                    }}
                                                >
                                                    <Col
                                                        md='12'
                                                        style={{
                                                            fontWeight: 'bold',
                                                        }}
                                                    >
                                                        Notes
                                                    </Col>
                                                    <Col md='12'>
                                                        <textarea
                                                            rows={3}
                                                            value={notes}
                                                            onChange={(e) =>
                                                                setNotes(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            className='form-control'
                                                            placeholder='Enter your notes'
                                                            wrap='soft'
                                                            name='notes'
                                                            maxLength={1000}
                                                        />
                                                    </Col>
                                                </Row>
                                            )}

                                            <br />
                                            {/* price */}
                                            {selectedProduct && (
                                                <>
                                                {
                                                   showMSRPPrice   && (
                                                <Row>
                                                    <Col md='4'>
                                                    <b
                                                            style={{
                                                                color: 'rgb(0, 0, 0)',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            MSRP:
                                                            </b>
                                                        
                                                    </Col>
                                                    <Col md='5'>
                                                        <b
                                                                style={{
                                                                    color: 'rgb(0, 0, 0)',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                        >
                                                    
                                                         
                                                               <PriceElement price={msrpPrice} show={showMSRPPrice} currency={configHeader.current.currency}/>
                                                         
                                                        </b>
                                                    </Col>
                                                </Row>
                                                            
                                                    )
                                                }

                                                    
                                                {

                                                    showWholeSalePrice && (
                                                <Row>
                                                    <Col md='4'>
                                                        <b
                                                                style={{
                                                                    color: 'rgb(0, 0, 0)',
                                                                    fontSize:
                                                                        '14px',
                                                                }}
                                                            >
                                                                WHOLESALE:
                                                                </b>  
                                                                </Col>
                                                    <Col md='5'>    
                                                    <b
                                                                style={{
                                                                    color: 'rgb(0, 0, 0)',
                                                                    fontSize:
                                                                        '14px',
                                                                       textDecoration:'line-through'
                                                                }}
                                                        >
                                                    
                                                            <PriceElement price={wholesalePrice} show={showWholeSalePrice} currency={configHeader.current.currency}/>
                                                           
                                                         </b>
                                                    </Col>
                                                </Row>      
                                                    )
                                                }                          

                                                
                                                {
                                                        showMyProductPrice && (
                                                <Row>
                                                    <Col md='4'>
                                                        <b
                                                            style={{
                                                                color: 'rgb(24, 187, 156)',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            MY PRICE: 
                                                            </b>
                                                    </Col>
                                                    <Col md='5'>
                                                         <b
                                                            style={{
                                                                color: 'rgb(24, 187, 156)',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                         <PriceElement price={myProductPrice} show={showMyProductPrice}  currency={configHeader.current.currency}/>
                                                            
                                                        </b>
                                                    </Col>
                                                </Row>

                                                        )
                                                    }
                                                       
                                                       <Row>
                                                    <Col md='4'>
                                                        <b
                                                            style={{
                                                                color: '#007DAB',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            
                                                            CONFIGURED: 
                                                            </b>
                                                    </Col>
                                                    <Col md='5'>
                                                         <b
                                                            style={{
                                                                color: '#007DAB',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                         <PriceElement price={configuredPrice} show={true}  currency={configHeader.current.currency}/>
                                                            
                                                        </b>
                                                    </Col>
                                                </Row>
                                                        
                                               {
                                                configuredPrice === "TBD" && (
                                                    <p style={{"marginTop": "15px"}}><b>PLEASE NOTE</b>: Your custom configuration request will need to be emailed to the James Engineering Team and is currently <b>Under Design Review</b>. You'll be notified by email upon design, drawing and pricing approval. Please partner with your Inside Sales Representative to place this order manually.</p>
                                                )
                                               }
                                                </>
                                                
                                            )}
                                            <br />
                                            {/* Save and Add to Cart etc. buttons */}
                                            {selectedProduct && !isGuest && (
                                                <>
                                                <Row>
                                                    <Col md='8'>
                                                        <Button
                                                            className='btn-custom mt-2'
                                                            color='primary'
                                                            style={{
                                                                float: 'left',
                                                                marginBottom:
                                                                    '10px',
                                                                marginRight:
                                                                    '10px',
                                                            }}
                                                            onClick={
                                                                handleSaveButtonClick
                                                            }
                                                        >
                                                            Save
                                                        </Button>
                                                        <AddtoCartButton
                                                            canAddToCart={
                                                                !customAnswerGiven &&  queryParams.canAddToCart
                                                            }
                                                            qty={qty}
                                                            onChangeQty={
                                                                handleQtyChange
                                                            }
                                                            onSave={
                                                                handleSaveButtonClick
                                                            }
                                                            onSendEmail={
                                                                handleSaveButtonClick
                                                            }
                                                        />
                                                       
                                                    </Col>
                                                    
                                                </Row>
                                                <Row>
                                                <Col md='4' style={{
                                                                marginTop: '20px' 
                                                             
                                                            }}>
                                                    <b
                                                            style={{
                                                                color: '#007DAB',
                                                                fontSize:
                                                                    '14px',
                                                            }}
                                                        >
                                                            EXT. NET PRICE:
                                                            </b>
                                                      
                                                    </Col>
                                                    <Col md='5' style={{
                                                                marginTop: '20px' 
                                                             
                                                            }}>
                                                    <b
                                                            style={{
                                                                color: '#007DAB',
                                                                fontSize:
                                                                    '14px',
                                                            }}>
                                                         <PriceElement price={configuredNetPrice} show={true}  currency={configHeader.current.currency}/>
                                                            
                                                        </b>
                                                        
                                                   </Col>
                                                </Row>
                                                </>
                                            )}
                                        </Col>
                                        {/* product image */}
                                        <Col md='6'>
                                            {selectedProduct &&
                                                selectedProduct.heroImg && (
                                                    <>
                                                        <img
                                                            src={
                                                                selectedProduct.heroImg
                                                            }
                                                            alt={
                                                                selectedProduct.description
                                                            }
                                                        />
                                                        <div className='heroImgCaption'>
                                                            {
                                                                selectedProduct.description
                                                            }
                                                        </div>

                                                        {
                                                            selectedProduct.productNote && (
                                                                <div className='productNote'>

                                                                    {selectedProduct.productNote}
                                                                </div>
                                                            )
                                                            
                                                        }

                                                    </>
                                                )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </div>
    );
};

const ConsoleLog = (props) =>{
    console.log("props", props);

    return (<></>);
}



export default QuestionsContainer;

/// helper functions
function getRulesForQuestions(questionObjList) {
 
    let dependentRules = {};
 
    for (let questionObj of questionObjList) {
        // populate rules object with rule used in the question
        if (!isEmpty(questionObj.question.ruleName)) {
            dependentRules[questionObj.question.ruleName] = questionObj.rules;
        }

        for (let option of questionObj.options) {
            // populate rules object with rules used in the option
            if (!isEmpty(option.ruleName)) {
                dependentRules[option.ruleName] = option.rules;
            }
        }
    }
    return dependentRules;
}

// function to evaluate given rule records
function evaluateRules(ruleRecords, answersObj, matrixData) {
    const evaluationResult = {};
 
    for (const [ruleName, rules] of Object.entries(ruleRecords)) {
        evaluationResult[ruleName] = EvaluateRule(
            rules,
            answersObj,
            matrixData
        );
    }
    return evaluationResult;
}

// checks if the answer given by user is a custom answer
function isCustomAnswer(answer, visibleOptions) {
   
    const customOption = visibleOptions.find(
        (option) => option.isCustomOption 
    );
    if (customOption && answer === customOption.optionValue) {
        return true;
    }
    return false;
}

// project dropdown styles
const projectDropdownStyles = {
    clearIndicator: (base) => ({
        ...base,
        color: '#7e7e7e',
        '&:hover': { color: '#7e7e7e' },
    }),
    container: (base, { isFocused }) => ({
        ...base,
        background: 'rgb(235, 235, 235)',
        marginTop: '9px',
        height: 'calc(2.25rem + 2px)',
        width: '100%',
        border: '1px solid #ced4da',
        borderRadius: 0,
        borderColor: isFocused ? '#80bdff' : '#ced4da',
        boxShadow: isFocused ? '0 0 0 0.2rem rgba(0, 123, 255, 0.25)' : 'none',
    }),
    control: (base) => ({
        ...base,
        background: 'transparent',
        border: 0,
        borderRadius: 0,
        boxShadow: 'none',
        padding: '0px 2px 0px 10px',
    }),
    dropdownIndicator: (base) => ({
        ...base,
        color: '#7e7e7e',
        backgroundColor: 'transparent',
        '&:hover': { color: '#7e7e7e' },
        padding: '0px',
    }),
    indicatorsContainer: (base) => ({
        ...base,
        '& > span': { display: 'none' },
    }),
    singleValue: (base) => ({
        ...base,
        color: '#7e7e7e',
    }),
    input: (base) => ({
        ...base,
        color: '#7e7e7e',
    }),
    valueContainer: (base) => ({
        ...base,
        paddingLeft: '0px',
    }),
    menu: (base) => ({
        ...base,
        zIndex: 5,
        borderRadius: 0,
        backgroundColor: 'rgb(235, 235, 235)',
        border: '1px solid #ced4da',
        margin: '0px 0px',
        boxShadow: 'none',
    }),
    menuList: (base) => ({
        ...base,
        background: 'rgb(235, 235, 235)',
        color: '#7e7e7e',
    }),
    option: (base, { isSelected }) => ({
        ...base,
        backgroundColor: isSelected ? '#1266d8' : 'transparent',
        color: isSelected ? '#ffffff' : '#7e7e7e',
        paddingTop: '0px',
        paddingBottom: '0px',
        '&:hover': { backgroundColor: '#1266d8', color: '#ffffff' },
    }),
};
