import React from 'react';
import { isEmpty } from '../../lib/utils';

const AnswerChoices = ({
    product,
    questionVariable,
    choices,
    isDropdown,
    selectedValue,
    ruleEvaluations,
    onAnswerGiven,
}) => {

    function handleChoiceOptionChange(event) {
        onAnswerGiven(event.target.value);
    }

    // show options dropdown or a textbox depending on available info
    if (isDropdown)
        return (
            <select
                name={`select_${product.productId}_${questionVariable}`}
                className='custom-select basic-single'
                value={selectedValue}
                onChange={(event) => handleChoiceOptionChange(event)}
            >
                <option value='<>'>Select an option</option>
                {choices.map(
                    (choice) =>
                        (isEmpty(choice.ruleName) ||
                            (!isEmpty(choice.ruleName) &&
                                ruleEvaluations[choice.ruleName] === true)) && (
                            <option
                                key={`${product.productId}_${questionVariable}_${choice.id}`}
                                value={choice.optionValue}
                            >
                                {choice.optionText}
                            </option>
                        )
                )}
            </select>
        );
    else
        return (
            <input
                type='text'
                name={`text_${product.productId}_${questionVariable}`}
                value={selectedValue}
                className='form-control'
                placeholder='Enter your value'
                onChange={(event) => handleChoiceOptionChange(event)}
            />
        );
};
export default AnswerChoices;
