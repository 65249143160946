import { evaluateMathOperation } from '../../lib/utils';
import MatrixLookup from './MatrixLookup';

/// evaluate given rule records
const EvaluateRule = (ruleRecords, answers, matrixData) => {
    
    // Note: This component, being called conditionally, should not use hooks
    // like useState and useContext etc. to maintain react hooks order. 
    if (ruleRecords.length === 0)
        throw new Error('No rules given for evaluation');

    
    const condOperator = ruleRecords[0].condOperator;
  
    if (
        condOperator === 'OR' || condOperator === '' ||
        !condOperator // blank or null or undefined
    ) {
        let result = false;
        for (let ruleRec of ruleRecords) {
            let { condVariable, condAnswer, condCompOperator } = ruleRec;
            const leftOperand =
                ruleRec.matrix === true
                    ? MatrixLookup(condVariable, answers, matrixData)
                    : (condVariable === "1" ? "1" : answers[condVariable]);

            const rightOperand = (condAnswer === "1" ? "1" : condAnswer);
            if (
                evaluateMathOperation(
                    leftOperand,
                    rightOperand,
                    condCompOperator
                )
            ) {
                result = true;
                break;
            }
        }
        return result;
    } else if (condOperator === 'AND') {
        let result = true;
        for (let ruleRec in ruleRecords) {
            let { condVariable, condAnswer, condCompOperator } = ruleRec;
            const leftOperand =
                ruleRec.matrix === true
                    ? MatrixLookup(condVariable, answers, matrixData)
                    : answers[condVariable];
            const rightOperand = condAnswer;

            if (
                evaluateMathOperation(
                    leftOperand,
                    rightOperand,
                    condCompOperator
                ) === false
            ) {
                result = false;
                break;
            }
        }
        return result;
    } else return true;
};

export default EvaluateRule;
